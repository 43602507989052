import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { IconMenu } from '@flexera/ui.component-library';
import { useHelpHref } from '@flexera/shell.navigation';
import { useFNMSHelpOverride } from '@flexera/fnms.core';
import { useFNMSCapabilityCheck } from '@flexera/fnms.shared';
import { environment, Environment } from '@flexera/lib.environment';
import { t } from 'ttag';
import { communityHelpUrl } from '@flexera/lib.utilities';
import { HelpIcon } from '../assets';

// @TODO: Overriding the max-width property from IconMenu's wrapper as a temporary fix for preventing overflow.
// The proper fix would involve changing the max-width on the core IconMenu wrapper but this will be a large
// change because the IconMenu is used many times throughout this project. Remove this override when that work is done.
const StyledWrapper = styled.div`
	#styled-icon-menu {
		max-width: max-content;
	}
`;

export const HelpNavLink = ({
	right,
	noWrapping,
	preference,
	customId
}: HelpNavLinkProps) => {
	const supportHref = useHelpHref();
	const { hasFNMS } = useFNMSCapabilityCheck();
	const fnmsSupportHref = useFNMSHelpOverride();
	const splashPage = window.location.pathname.includes('splash');
	const fnmsView = window.location.pathname.includes('slo');

	const [submitFeedbackOptionId, setSubmitFeedbackOptionId] = useState(
		'submit-feedback'
	);

	const location = useLocation();

	useEffect(() => {
		if (location.pathname.includes('platform/reports/risk')) {
			setSubmitFeedbackOptionId('all-reports-submit-feedback');
			return;
		}
		if (location.pathname.includes('viz')) {
			setSubmitFeedbackOptionId(`custom-report-submit-feedback`);
			return;
		}
		if (location.pathname.includes('hif')) {
			const report = location.pathname.split('/').pop();
			setSubmitFeedbackOptionId(`hif-report-${report}-submit-feedback`);
			return;
		}
		if (location.pathname.includes('vis')) {
			const report = location.pathname.split('/').pop();
			setSubmitFeedbackOptionId(`vis-report-${report}-submit-feedback`);
		}
	}, [location.pathname]);

	const handleSupportClick = () => {
		if (hasFNMS && fnmsView) {
			window.open(fnmsSupportHref, '_blank');
		} else {
			window.open(supportHref, '_blank');
		}
		return true;
	};

	const handleCommunityClick = () => {
		window.open(communityHelpUrl, '_blank');
	};

	const handleSendFeedbackClick = () => {
		return true;
	};

	const headerHelpNavItems = [
		{
			id: 'view-documentation',
			onClick: handleSupportClick,
			target: '_blank',
			subtitle: t`View Documentation`,
			titleAttribute: t`View detailed feature documentation on docs.flexera.com`,
			itemicon: true,
			hasdivider: false
		},
		{
			id: 'get-help-on-community',
			onClick: handleCommunityClick,
			target: '_blank',
			subtitle: t`Get help in Community`,
			titleAttribute: t`Get help and discuss this feature on the Flexera Community`,
			itemicon: true,
			hasdivider: preference === 'true' && environment === Environment.Staging
		}
	];

	const submitFeedbackOption = {
		id: submitFeedbackOptionId,
		onClick: handleSendFeedbackClick,
		target: '_blank',
		subtitle: t`Submit Feedback`,
		titleAttribute: t`Submit feedback and request improvements to this feature`,
		itemicon: false,
		hasdivider: false
	};

	if (preference === 'true' && environment === Environment.Staging) {
		headerHelpNavItems.push(submitFeedbackOption);
	}

	return (
		<StyledWrapper>
			<IconMenu
				iconOnly
				noWrapping={noWrapping}
				right={right}
				icon={HelpIcon}
				customId={customId}
				variant={'minimalSecondary'}
				id={splashPage ? 'help-icon-splash' : 'help-icon'}
				options={
					headerHelpNavItems &&
					headerHelpNavItems.map((option) => {
						return {
							id: option?.id,
							subtitle: option?.subtitle,
							titleAttribute: option?.titleAttribute,
							hasdivider: option?.hasdivider,
							itemicon: option?.itemicon,
							onClick: option?.onClick,
							onKeyPress: option?.onClick,
							...option
						};
					})
				}
			/>
		</StyledWrapper>
	);
};

export interface HelpNavLinkProps {
	/** positions menu to the right of button */
	right?: boolean;
	/** kills word wrapping styles */
	noWrapping?: boolean;
	preference?: string | Record<string, unknown> | string[];
	customId: boolean;
}
