import styled, { css } from 'styled-components';
import { theme } from './theme';

interface HeaderProps {
	currentPath?: string;
	className?: string;
}

const isContainer = css`
	padding-left: ${theme.space.lg};
	padding-right: ${theme.space.md};
	width: 100%;
`;

export const HeaderStyled = styled.header<HeaderProps>`
	background-color: ${theme.colors.gray05};
	align-items: center;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	font-family: ${theme.fonts.primary};
	height: 64px;
	justify-content: space-between;
	min-width: 100%;
	${isContainer};

	${({ currentPath }) => {
		if (currentPath.includes('landing')) {
			return css`
				background-color: ${theme.colors.light};
			`;
		}
		return css``;
	}};

	@media screen and (max-width: 480px) {
		flex-direction: column;
		align-items: unset;
		height: 100px;
	}
`;

export const HeaderLeft = styled.div`
	width: 100%;
	@media screen and (max-width: 480px) {
		order: 2;
	}
`;
export const StyledHeaderRight = styled.ul`
	align-items: center;
	display: flex;
	height: 100%;
	padding-left: 0;
`;

interface HeaderItemProps {
	currentPath: string;
}

export const HeaderItem = styled.li<HeaderItemProps>`
	align-items: center;
	justify-content: center;
	gap: ${theme.space.xs};
	display: flex;
	height: 100%;
	list-style: none;
	min-width: 40px;
	padding-left: ${theme.space.xs};
	padding-right: ${theme.space.xs};

	&:hover {
		#help-icon-splash path,
		#header-profile svg path {
			fill: #333333 !important;
		}
	}

	> div {
		margin: 0;
	}

	#help-icon-splash,
	#help-icon {
		align-items: center;
		border-radius: 50%;
		cursor: pointer;
		display: flex;
		height: 32px;
		justify-content: center;
		outline: none !important;
		width: 32px;

		&:focus {
			box-shadow: 0 0 0 3px #99d9f2;
		}
	}

	#header-org-switcher {
		font-family: ${theme.fonts.primary};

		#orgswitcher-link {
			text-transform: uppercase;

			+ svg {
				margin-right: -5px;
				transform: scale(0.75);
			}

			${({ currentPath }) => {
				if (currentPath.includes('splash')) {
					return css`
						color: ${theme.colors.light};

						+ svg g path:nth-of-type(2) {
							fill: ${theme.colors.light};
						}

						&:hover {
							color: ${theme.colors.text};
						}
					`;
				}
				return css`
					color: ${theme.colors.text};
				`;
			}};
		}

		a {
			font-family: ${theme.fonts.primary};
			font-weight: ${theme.fontWeights.regular};
		}
	}

	${({ currentPath }) => {
		if (currentPath.includes('splash')) {
			return css`
				&:hover {
					#header-org-switcher {
						font-family: ${theme.fonts.primary};

						#orgswitcher-link {
							color: ${theme.colors.text};

							+ svg g path:nth-of-type(2) {
								fill: ${theme.colors.text};
							}
						}
					}
				}
			`;
		}
		return css``;
	}};

	#header-profile {
		border: none;
		line-height: 1;

		svg path {
			fill: ${({ currentPath }) =>
				currentPath.includes('splash') ? '#FFFFFF' : '#333333'};
		}

		+ div {
			display: none;
		}
	}
`;

interface HelpIconWrapperProps {
	splashPage: boolean;
}

export const HelpIconWrapper = styled.span<HelpIconWrapperProps>`
	svg,
	svg path {
		fill: ${({ splashPage }) => (splashPage ? '#FFFFFF' : '#333333')};
	}
`;
