import styled, { css } from 'styled-components';
import { BaseTheme } from '@flexera/ui.component-library';
import {
	activeBackground,
	activeLeftBorder,
	insetShadowLeft,
	linkStyle,
	listItemStyle,
	svgStyle,
	svgFillDefault,
	expandedNavWidth,
	collapsedNavWidth
} from '../constants';
import { PinButtonBox } from '../PinButton/PinButton.styled';
import { SubList } from '../Submenu/Submenu.styled';

interface ListItemProps {
	isExpanded: boolean;
	showActiveSearchStyle: boolean;
	showFlyoutContent?: boolean;
	isPinned?: boolean;
	flyoutOpen?: boolean;
}

export const ListItem = styled.li<ListItemProps>`
	${({ showActiveSearchStyle, isExpanded, isPinned }) => {
		if (showActiveSearchStyle) {
			return css`
				display: flex;
				width: 100%;
				flex-flow: column nowrap;
				cursor: pointer;

				span.icon-and-label-wrapper {
					background: #3b4050;
					display: flex;
					align-items: center;
					width: 100%;
					height: 40px;

					${linkStyle}

					span {
						margin-right: auto;
					}

					svg {
						padding: 0 ${BaseTheme.space.xs};
						${svgStyle}
						g[fill='#000'] {
							fill: ${svgFillDefault};
						}
					}
				}
			`;
		}

		// default return
		return css`
			${listItemStyle}
			display: flex;
			align-items: center;
			width: 100%;
			min-width: {isExpanded ? expandedNavWidth : collapsedNavWidth};
			height: 40px;
			padding: 0 0 0 ${BaseTheme.space.xs};
			border-left: 2px solid transparent;
			outline: none;
			:focus,
			:focus-within {
				background: ${activeBackground};
			}
			&.navlink {
				.main-nav-link {
						outline: none;
						display: flex;
						width: 100%;
						height: 100%;
						align-items: center;
					}
				:hover {
					background: ${activeBackground};
				}
			}
			div.flyout-drawer {
				transition: width 200ms, left 200ms;
			}

			${
				!isPinned &&
				css`
					div.flyout-drawer {
						width: 0px;
						${SubList} {
							display: none;
						}
						${PinButtonBox} {
							width: 0px;
							visibility: hidden;
						}
						.resizer-button {
							display: none;
						}
					}

					&.active {
						background: ${activeBackground};
						border-left: ${activeLeftBorder};
						div.flyout-drawer {
							z-index: 160;
							width: var(--flyout-width);
							${SubList} {
								display: block;
							}
							${PinButtonBox} {
								visibility: visible;
								opacity: 1;
								width: calc(100% - 2px);
							}
							.resizer-button {
								display: unset;
							}
							:active {
								transition: none;
							}
						}
					}
				`
			}

			span.icon-and-label-wrapper {
				display: flex;
				flex-wrap: nowrap;
				max-height: ${BaseTheme.space.md};
				align-items: center;
				cursor: pointer;
				color: ${BaseTheme.colors.light};

				${linkStyle}

				& span {
					margin-right: auto;
				}

				& span[id^='new-pill-'] {
					margin-right: 0.75rem;
				}

				&.full-width {
					flex: 1;
				}

				& svg {
					display: block;
					min-width: 24px;
					padding: ${
						isExpanded ? `0 1rem 0 ${BaseTheme.space.xs}` : `0 ${BaseTheme.space.xxs}`
					};
					${svgStyle}

					g[fill='#000'] {
						fill: ${svgFillDefault};
					}
				}
			}
		`; // end default css
	}}// end opening props-function block
`;

interface FlyoutDrawerProps {
	isExpanded: boolean;
}
export const FlyoutDrawer = styled.div<FlyoutDrawerProps>`
	grid-template-columns: repeat(4, 1fr) 25px;
	background: rgb(8, 13, 29);
	position: absolute;
	top: 0;
	transition: left 200ms;
	left: ${({ isExpanded }) =>
		isExpanded ? expandedNavWidth : collapsedNavWidth};
	z-index: 149;
	max-width: 480px;
	height: 100%;
	cursor: default;
	button.resizer-button {
		transition: opacity 300ms;
		opacity: 0;
	}
	${insetShadowLeft}
	&:hover {
		button.resizer-button {
			opacity: 1;
		}
	}
`;
