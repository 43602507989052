import { useAuthenticated } from '@flexera/auth.base';
import {
	useRestrictITV,
	useRestrictITVDataExports,
	useCustomDashboardITV,
	useLegacyDashboardITV,
	useOffboardITV,
	useHideTIPlatform
} from '@flexera/flexera-one.feature-flags';
import { Permissions, usePermissionSummary } from '@flexera/lib.permissions';
import {
	IT_VISIBILITY,
	APPLICATIONS_AND_EVIDENCE__ITV_VISIBILITY,
	INVENTORY__ITV_VISIBILITY,
	DASHBOARDS,
	DATA_COLLECTION__UDI
} from '@flexera/shell.base-nav-tree';
import {
	loadingItem,
	MenuItem,
	useNavItems,
	LabelType
} from '@flexera/shell.navigation';
import { OrgsLoading, useCurrentOrg, useOrgId } from '@flexera/shell.orgs';
import { useEffect } from 'react';
import { t } from 'ttag';
import { helpDocBaseUrl } from '@flexera/lib.utilities';
import * as ids from './menuIds';

export function useNav() {
	const authenticated = useAuthenticated();
	const hideTIPlatform = useHideTIPlatform();
	// org comes from state
	const org = useCurrentOrg();
	// orgId comes from URL
	const orgId = useOrgId();
	const { addItems } = useNavItems();

	const restrictITVAccess = useRestrictITV();
	const restrictITVDataExportsAccess = useRestrictITVDataExports();
	const hasCustomDashboards = useCustomDashboardITV();
	const hasLegacyDashboards = useLegacyDashboardITV();
	const hasOffboardITV = useOffboardITV();

	const [perms, loadingPerms] = usePermissionSummary(
		`/orgs/${org.id}`,
		Permissions.hasITVisibility,
		Permissions.canSeeITVDashboard,
		Permissions.canSeeITVDataExport,
		Permissions.canSeeITVConnections,
		Permissions.canSeeITVConnection,
		Permissions.hasCloudManagement,
		Permissions.canUploadITVData,
		Permissions.hasUnifiedObjectModel,
		Permissions.canSeeITVSoftwareEvidenceGrid,
		Permissions.canSeeITVSoftwareEvidenceStats,
		Permissions.canSeeITVSoftwareEvidenceDetail,
		Permissions.canSeeITVHardwareEvidenceGrid,
		Permissions.canSeeITVHardwareEvidenceStats,
		Permissions.canSeeITVDeviceList,
		Permissions.canSeeITVDeviceSummary,
		Permissions.canSeeITVDeviceDetail,
		Permissions.canSeeITVPrivateReleases,
		Permissions.canSeeITVPrivateReleasesSummary,
		Permissions.canSeeITVSoftwareReleasesDetail,
		Permissions.canViewITVReports
	);

	useEffect(() => {
		if (!authenticated || !orgId) return;

		if (org === OrgsLoading || loadingPerms) {
			return addItems([loadingItem(ids.LOADING)]);
		}

		const defaultRouteSet = [] as MenuItem[];

		if (!perms.get(Permissions.hasITVisibility)) return;

		if (restrictITVAccess) return;

		if (
			perms.get(Permissions.canSeeITVDashboard) &&
			hasCustomDashboards &&
			!hasOffboardITV
		) {
			defaultRouteSet.push({
				id: ids.CUSTOMDASHBOARD,
				parentId: IT_VISIBILITY,
				path: `/orgs/${orgId}/itv/custom-dashboard`,
				urlMatch: /^\/orgs\/\d+\/itv\/custom-dashboard(\/|$|\?)/,
				helpHref: `${helpDocBaseUrl}/ITVisibility/dashboards.htm`,
				label: t`Dashboard`,
				priority: 20
			});
			defaultRouteSet.push({
				id: ids.ADD,
				parentId: IT_VISIBILITY,
				path: `/orgs/${orgId}/itv/custom-dashboard/add`,
				urlMatch: /^\/orgs\/\d+\/itv\/custom-dashboard\/add(\/|$|\?)/,
				helpHref: `${helpDocBaseUrl}/ITVisibility/dashboards.htm`,
				label: t`Add Dashboard`,
				priority: 30,
				hidden: true
			});
			defaultRouteSet.push({
				id: ids.CUSTOMEXPLORER,
				parentId: IT_VISIBILITY,
				path: `/orgs/${orgId}/itv/custom-dashboard/explorer`,
				urlMatch: /^\/orgs\/\d+\/itv\/custom-dashboard\/explorer(\/|$|\?)/,
				helpHref: `${helpDocBaseUrl}/ITVisibility/DataExplorer.htm`,
				label: t`Data Explorer`,
				priority: 40,
				hidden: true
			});
		}

		if (
			perms.get(Permissions.canSeeITVDataExport) &&
			!restrictITVDataExportsAccess
		) {
			defaultRouteSet.push({
				id: ids.EXPORTS,
				parentId: hasOffboardITV ? DATA_COLLECTION__UDI : IT_VISIBILITY,
				path: `/orgs/${orgId}/itv/exports`,
				urlMatch: /^\/orgs\/\d+\/itv\/exports(\/|$|\?)/,
				helpHref: `${helpDocBaseUrl}/ITVisibility/exportdata.htm`,
				label: t`Data Exports`,
				priority: 50
			});
		}

		if (
			perms.get(Permissions.canSeeITVConnections) ||
			perms.get(Permissions.canSeeITVConnection)
		) {
			defaultRouteSet.push({
				id: ids.CONNECTIONS,
				parentId: hasOffboardITV ? DATA_COLLECTION__UDI : IT_VISIBILITY,
				path: `/orgs/${orgId}/itv/connections`,
				urlMatch: /^\/orgs\/\d+\/itv\/connections(\/|$|\?)/,
				helpHref: `${helpDocBaseUrl}/ITVisibility/ServiceNow.htm`,
				label: hasOffboardITV ? t`ServiceNow Connection` : t`Connections`,
				priority: 60
			});
		}

		if (
			hasCustomDashboards &&
			!hasOffboardITV &&
			perms.get(Permissions.canUploadITVData)
		) {
			defaultRouteSet.push({
				id: ids.CSVUPLOADER,
				parentId: IT_VISIBILITY,
				path: `/orgs/${orgId}/itv/data-mashup`,
				urlMatch: /^\/orgs\/\d+\/itv\/data-mashup(\/|$|\?)/,
				helpHref: `${helpDocBaseUrl}/ITVisibility/datamashup.htm`,
				label: t`Data Mashup`,
				priority: 65
			});
		}

		if (
			!hideTIPlatform &&
			perms.get(Permissions.hasUnifiedObjectModel) &&
			perms.get(Permissions.canViewITVReports)
		) {
			// Only to be used as a menu item to point to Dashboards > All Reports page
			// Will not be considered for URL matching for breadcrumb inference
			defaultRouteSet.push({
				id: `${ids.POWERBIDASHBOARD}-from-itv`,
				parentId: IT_VISIBILITY,
				path: `/orgs/${orgId}/platform/reports`,
				helpHref: `${helpDocBaseUrl}/ITVisibility/OOTBReportDescriptions.htm`,
				label: t`Reports`,
				priority: 1,
				hasDivider: true,
				labelType: LabelType.NEW
			});
		}

		if (
			perms.get(Permissions.hasUnifiedObjectModel) &&
			perms.get(Permissions.canViewITVReports)
		) {
			defaultRouteSet.push({
				id: ids.POWERBIDASHBOARD,
				parentId: DASHBOARDS,
				path: `/orgs/${orgId}/platform/reports/risk`,
				urlMatch: /^\/orgs\/\d+\/vis\/report(\/|$|\?)/,
				helpHref: `${helpDocBaseUrl}/ITVisibility/OOTBReportDescriptions.htm`,
				hidden: true,
				label: t`All Reports`
			});
		}

		if (
			perms.get(Permissions.canSeeITVSoftwareEvidenceGrid) &&
			perms.get(Permissions.canSeeITVSoftwareEvidenceStats)
		) {
			defaultRouteSet.push({
				id: ids.SOFTWAREEVIDENCE,
				parentId: APPLICATIONS_AND_EVIDENCE__ITV_VISIBILITY,
				path: `/orgs/${orgId}/itv/software-evidence`,
				urlMatch: /^\/orgs\/\d+\/itv\/software-evidence(\/|$|\?)/,
				helpHref: `${helpDocBaseUrl}/ITVisibility/PrivateCatSW.htm`,
				label: t`Software Evidence`,
				priority: 66
			});
		}

		if (perms.get(Permissions.canSeeITVSoftwareEvidenceDetail)) {
			defaultRouteSet.push({
				parentId: ids.SOFTWAREEVIDENCE,
				id: ids.SOFTWAREEVIDENCEDETAIL,
				path: `/orgs/${orgId}/itv/software-evidence/:evidenceId/:tabView?`,
				helpHref: `${helpDocBaseUrl}/ITVisibility/PrivateCatSW.htm`,
				urlMatch: /^\/orgs\/\d+\/itv\/software-evidence\/(|$|\?)/,
				hidden: true,
				variableTitle: true
			});
		}

		if (perms.get(Permissions.canSeeITVSoftwareEvidenceStats)) {
			defaultRouteSet.push({
				id: ids.PRIVATECATALOGEVIDENCE,
				parentId: APPLICATIONS_AND_EVIDENCE__ITV_VISIBILITY,
				path: `/orgs/${orgId}/itv/private-catalog-evidence`,
				urlMatch: /^\/orgs\/\d+\/itv\/private-catalog-evidence(\/|$|\?)/,
				helpHref: `${helpDocBaseUrl}/ITVisibility/PrivateCatSW.htm`,
				label: 'Private Catalog Evidence',
				priority: 67
			});
		}

		if (perms.get(Permissions.canSeeITVSoftwareEvidenceGrid)) {
			defaultRouteSet.push({
				parentId: ids.PRIVATECATALOGEVIDENCE,
				id: ids.PRIVATECATALOGEVIDENCECOUNTTYPES,
				path: `/orgs/${orgId}/itv/private-catalog-evidence/:filterType`,
				urlMatch: /^\/orgs\/\d+\/itv\/private-catalog-evidence\/(|$|\?)/,
				helpHref: `${helpDocBaseUrl}/ITVisibility/PrivateCatSW.htm`,
				hidden: true,
				variableTitle: true
			});
		}

		if (perms.get(Permissions.canSeeITVSoftwareReleasesDetail)) {
			defaultRouteSet.push({
				parentId: ids.PRIVATECATALOGRELEASES,
				id: ids.PRIVATECATALOGRELEASESDETAIL,
				path: `/orgs/${orgId}/itv/private-catalog-releases/:releaseId/:tabView?`,
				urlMatch: /^\/orgs\/\d+\/itv\/private-catalog-releases\/(|$|\?)/,
				helpHref: `${helpDocBaseUrl}/ITVisibility/PrivateCatSW.htm`,
				hidden: true,
				variableTitle: true
			});
		}

		if (
			perms.get(Permissions.canSeeITVPrivateReleases) &&
			perms.get(Permissions.canSeeITVPrivateReleasesSummary)
		) {
			defaultRouteSet.push({
				id: ids.PRIVATECATALOGRELEASES,
				parentId: APPLICATIONS_AND_EVIDENCE__ITV_VISIBILITY,
				path: `/orgs/${orgId}/itv/private-catalog-releases`,
				urlMatch: /^\/orgs\/\d+\/itv\/private-catalog-releases(\/|$|\?)/,
				helpHref: `${helpDocBaseUrl}/ITVisibility/PrivateCatSW.htm`,
				label: 'Private Catalog Releases',
				priority: 68
			});
		}

		if (
			perms.get(Permissions.canSeeITVHardwareEvidenceGrid) &&
			perms.get(Permissions.canSeeITVHardwareEvidenceStats)
		) {
			defaultRouteSet.push({
				id: ids.HARDWAREEVIDENCE,
				parentId: INVENTORY__ITV_VISIBILITY,
				path: `/orgs/${orgId}/itv/hardware-evidence`,
				urlMatch: /^\/orgs\/\d+\/itv\/hardware-evidence(\/|$|\?)/,
				helpHref: `${helpDocBaseUrl}/ITVisibility/HW-Evid.htm`,
				label: t`Hardware Evidence`,
				priority: 5
			});
		}

		if (perms.get(Permissions.canSeeITVDeviceDetail)) {
			defaultRouteSet.push({
				parentId: ids.DEVICES,
				id: ids.DEVICEDETAIL,
				path: `/orgs/${orgId}/itv/devices/:deviceId/:tabView?`,
				urlMatch: /^\/orgs\/\d+\/itv\/devices\/(|$|\?)/,
				helpHref: `${helpDocBaseUrl}/ITVisibility/Device-Evid.htm`,
				hidden: true,
				variableTitle: true
			});
		}

		if (
			perms.get(Permissions.canSeeITVDeviceList) &&
			perms.get(Permissions.canSeeITVDeviceSummary)
		) {
			defaultRouteSet.push({
				id: ids.DEVICES,
				parentId: INVENTORY__ITV_VISIBILITY,
				path: `/orgs/${orgId}/itv/devices`,
				urlMatch: /^\/orgs\/\d+\/itv\/devices(\/|$|\?)/,
				helpHref: `${helpDocBaseUrl}/ITVisibility/Device-Evid.htm`,
				label: t`Devices`,
				priority: 4
			});
		}

		return addItems(defaultRouteSet);
	}, [
		org,
		orgId,
		authenticated,
		loadingPerms,
		perms,
		restrictITVAccess,
		restrictITVDataExportsAccess,
		hasCustomDashboards,
		hasLegacyDashboards,
		hasOffboardITV,
		hideTIPlatform
	]);
}
