import React from 'react';
import { flushSync } from 'react-dom';
import { Link } from 'react-router-dom';
import {
	PillInfo,
	getPillTextFromLabelType,
	NavItemChild
} from '@flexera/shell.navigation';
import { t } from 'ttag';
import { Icon, IconMdOpenNewTab, Pill } from '@flexera/ui.component-library';
import { determineLinkTo } from './determineLinkTo';
import { addHighlightAroundSearchTerm } from './addHighlightAroundSearchTerm';
import {
	CategoryList,
	CategoryListDescription,
	FavoriteableListItem,
	GrandChildList,
	Divider
} from '../src/Submenu/Submenu.styled';
import { FavoriteButton } from '../src/Favorites/FavoritesButton/FavoriteButton';

export const getChildItems = (
	item: NavItemChild,
	searchIsActive,
	setSearchIsActive,
	searchWord,
	setIsActive?,
	setFlyoutOpen?,
	hasPeers?
): JSX.Element | undefined => {
	if (!item.label || item.hidden) {
		return;
	}

	const showSearchStyle = searchIsActive ? 'active-search-item' : '';

	const handleLinkClick = () => {
		if (setIsActive && setFlyoutOpen) {
			flushSync(() => {
				setIsActive(false);
			});
			flushSync(() => {
				setFlyoutOpen(false);
			});
		}
		if (item.onClick) {
			item.onClick();
		}
		if (setSearchIsActive) {
			flushSync(() => {
				setSearchIsActive(false);
			});
		}
	};

	if (item.category && !item.external) {
		return (
			<CategoryList key={`${item.id}-category`} className={showSearchStyle}>
				<CategoryListDescription tabIndex={-1}>
					{searchIsActive
						? addHighlightAroundSearchTerm(item.category, searchWord)
						: item.category}
				</CategoryListDescription>
				<GrandChildList role={'menu'} tabIndex={-1}>
					{item.children.map((grandChild) =>
						getChildItems(
							grandChild,
							searchIsActive,
							setSearchIsActive,
							searchWord,
							setIsActive,
							setFlyoutOpen,
							false
						)
					)}
				</GrandChildList>
			</CategoryList>
		);
	}

	const { id } = item;
	let labelInfo: PillInfo | undefined;

	if (item.labelType) {
		labelInfo = getPillTextFromLabelType(item.labelType);
	}

	return (
		<React.Fragment key={id}>
			<FavoriteableListItem
				tabIndex={-1}
				className={showSearchStyle}
				role={'presentation'}
				data-test-id={item.id}
			>
				{item.external && item.path ? (
					<a
						id={item.id as string}
						aria-label={item.label}
						href={determineLinkTo(item)}
						rel={item.path && 'noreferrer'}
						target={item.path && '_blank'}
						onClick={handleLinkClick}
					>
						{searchIsActive
							? addHighlightAroundSearchTerm(item.label, searchWord)
							: item.label}
						<Icon
							color={'#BBB2B0'}
							position={'inherit'}
							src={IconMdOpenNewTab}
							height={'16px'}
							width={'16px'}
							viewBox={'0 0 26 26'}
							verticalAlign={'middle'}
							pl={'4px'}
						/>
					</a>
				) : (
					<Link
						id={item.id as string}
						aria-label={item.label}
						to={determineLinkTo(item)}
						onClick={handleLinkClick}
					>
						{searchIsActive
							? addHighlightAroundSearchTerm(item.label, searchWord)
							: item.label}
						{item.external && (
							<Icon
								color={'#BBB2B0'}
								position={'inherit'}
								src={IconMdOpenNewTab}
								height={'16px'}
								width={'16px'}
								viewBox={'0 0 26 26'}
								verticalAlign={'middle'}
								pl={'4px'}
							/>
						)}
					</Link>
				)}
				{labelInfo?.text && (
					<Pill
						id={`${item.id}`}
						variant={labelInfo.pillVariant}
					>{t`${labelInfo.text}`}</Pill>
				)}
				<FavoriteButton itemId={id} itemLabel={item.label} />
			</FavoriteableListItem>
			{hasPeers && item.hasDivider && <Divider />}
		</React.Fragment>
	);
};
